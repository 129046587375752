<template>
	<section class="activity-overview">
		<div class="max-width-1000">
			<quote-title label="最新活動" />
			<br>
			<div class="row-grid" gutter-x="30" gutter-y="20">
				<template v-for="(item, i) in _activities">
					<router-link :to="`/activity/${item.id}`" class="col rwd-l-3 rwd-m-4 rwd-s-6" :key="item.id || i">
						<thumb :src="item.thumb" />
						<br>
						<div class="col" gutter="10">
							<p style="text-align:center;">{{item.title}}</p>
							<small style="text-align:center;">{{item.sub_title}}</small>
						</div>
						<br>
					</router-link>
				</template>
			</div>
			<br>
		</div>
	</section>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
	components: {
		Thumb: () => import("@/components/thumb")
	},
	computed: {
		...mapState("activity", ["_activities"])
	},
	methods: {
		...mapActions("activity", ["_getList"]),
	},
	async created() {
		if (!this._activities.length) {
			await this._getList();
		}
	}
}
</script>

<style lang="scss" scoped>
.activity-overview {
	padding: 50px 5%;
	position: relative;
	background-size: 100%, 100%, 270px;
	background-repeat: no-repeat;
	background-image: url("../../../assets/background/brush3.png"),
		url("../../../assets/background/dot5.png"),
		url("../../../assets/background/miscanthus1.png");
	background-position: center bottom, center 80%, 85% calc(100% + 10px);

	@media screen and (max-width: 768px) {
		background-size: 100%, 100%, 200px;
	}

	@media screen and (max-width: 425px) {
		background-size: 100%, 100%, 0;
	}
}
</style>